<template>
  <div class="bordered">
    <Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch" @curveFun="curveFun">基础数据</Title>
    <TotalAnalysis style="marginBottom: 20" :value="valueByLevel"></TotalAnalysis>
    <BaseEchart :option="option" style="width: 100%; height: 274px"></BaseEchart>
  </div>
</template>
<script>
import Title from '../components/Title.vue'
import TotalAnalysis from '../components/TotalAnalysis.vue'
import BaseEchart from '@/components/BaseEchart';
import MockChinaData from '@/utils/MockChinaData'
import { random } from '@/utils'
import { mutBarChart1 } from '../common'
import { mapState } from 'vuex';
import { fetchRegionSummaryNotations, mergeValueByRegion } from '../../../request/api';

export default {
  components: { Title, TotalAnalysis, BaseEchart },
  data() {
    return {
      option: undefined,
      analysisNum: 40758,
      isShowSwitch: true,
      isSwitchOpen: true,
      valueByLevel: 0
    }
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    ...mapState('homePage', ['timeRange']),
  },
  methods: {
    async fetchBasicData() {
      const data = await fetchRegionSummaryNotations({ itemName: '基础数据;基础数据-60岁以上;基础数据-35岁以上', fullTime: this.isSwitchOpen, descendantLevel: 1 })
      let max = data.data.collection?.[0]?.intValue
      const arrData = mergeValueByRegion(data.data.collection, [{ from: '35岁以上', as: 'value1' }, { from: '60岁以上', as: 'value2' }])
      this.valueByLevel = arrData.reduce((sum, cur) => {
        sum = sum + cur.value1 + cur.value2
        return sum
      }, 0)
      this.$store.state.homePage.block.basicData = this.valueByLevel
      this.option = mutBarChart1({
        leArr: ['35岁以上', '60岁以上'],
        xData: arrData.map((d) => d.name),
        yFirst: arrData.map((d) => d.value1),
        ySecond: arrData.map((d) => d.value2),
      }, { max: max + 6 });
    },
    init() {
      this.fetchBasicData()
      const dataX = Object.keys(MockChinaData).map(k => {
        return { name: k, value: random(0, 100) }
      })
      // this.option = mutBarChart1({
      //   leArr: ['35岁以上', '60岁以上'],
      //   xData: dataX.map((d) => d.name),
      //   yFirst: dataX.map((d) => d.value * 5),
      //   ySecond: dataX.map((d) => d.value * 8),
      // });
    },
    changeSwitch(val) {
      this.isSwitchOpen = val
      this.init()
    },
  },
  watch: {
    addressInfo: {
      handler() {
        if(this.addressInfo.regionCode){
          this.init()
        }
      }
    },
    timeRange: {
      handler() {
        this.init()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bordered {
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
</style>