<template>
	<div class="bordered mt16">
		<Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch"  @curveFun="curveFun">慢性病管理</Title>
		<BaseEchart :option="option" style="margin-top: -10px; width: 100%; height: 295px;margin-left: 20px;"></BaseEchart>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import Title from '../components/Title.vue';
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
import BaseEchart from '@/components/BaseEchart';
import { fetchRegionSummaryNotations, mapDataNormal } from '../../../request/api';


export default {
	components: { Title, countTo ,BaseEchart},
	data() {
		return {
      CountAnimationDuration,
			value: 2145,
			data:[
      { value: 0, name: '精神病' },
      { value: 0, name: '肺结核' },
      { value: 0, name: '高血压' },
      { value: 0, name: '二型糖尿病' },
      { value: 0, name: '其他' },
      ],
			option: undefined,
      isShowSwitch:true,
      isSwitchOpen:true
		};
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
		timeRange() {
			return this.$store.state.homePage.timeRange;
		},
    valueFun(){
      return this.value / (this.mapLevel + 1)
    },
    tableDataFun(){
      return this.tableData.map(s => {
        return {
          ...s,
          data2: s.data2 / (this.mapLevel + 1),
          data3: s.data3 / (this.mapLevel + 1),
          data4: s.data4 / (this.mapLevel + 1),
          data5: s.data5 / (this.mapLevel + 1)
        }
      })
    },
	valueByLevel(){
      return this.data.map(s => {
        return {
          ...s,
          value:  Math.round(s.value /(this.mapLevel + 1)),
        }
      })
    },
	pieTotal(){
		let sum=0
		this.data.map(item=>{
			sum +=item.value
		}
		)
		return sum
	}
	},
	mounted() {
		// this.init()
	},
	methods: {
		async init() {
      const data = await fetchRegionSummaryNotations({'itemName': '慢性病管理-糖尿病;慢性病管理-高血压;慢性病管理-肺结核;慢性病管理-精神病;慢性病管理-其他', fullTime: this.isSwitchOpen})
      this.data = mapDataNormal(this.data, data.data?.collection || [])
      this.$store.state.homePage.block.diseaseManage = this.pieTotal
      this.option = {
        color: ['#F9A168', '#FBD75B', '#4EF5FF', '#4BFDCF', '#307BE3'],
        // tooltip: {
        //   trigger: 'item',
        // },
        legend: {
			show:false,
          bottom: 0,
          padding: [0, 0, 20, 0],
          formatter: function (name) {
            return '{a|' + name + '}'
          },
          icon: 'circle',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'white',
            backgroundColor: 'transparent',
            rich: {
              a: {
                width: 80,
                fontSize: 14
              },
            },
          },
        },
        grid: {
          top: 0,
        },
        graphic: {
          elements: [
            {
              type: 'circle',
              shape: {
                r: 110,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 50,
            },
            {
              type: 'circle',
              shape: {
                r: 68,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 95,
            },
            {
              type: 'image',
              style: {
                image: require('../assets/pie-bg2.jpg'),
                width: 120,
                height: 120,
              },
              left: 'center',
              top: 105,
            },
            {
              type: 'text',
              left: '42.5%',
              top: 145,
              style: {
                fill: '#ffffff',
                text: '慢性病总数',
                font: '16px sans-serif',
                textAlign: 'center',

              },
            },
            {
              type: 'text',
              left: 'middle',
              bounding: 'raw',
              left: 'center',
              top: 170,
              style: {
                fill: '#00F7F9',
                text: this.pieTotal,
                font: '28px sans-serif',
                textAlign: 'center',
              },
            },
          ],
        },
        series: [
          {
            type: 'pie',
            top: 30,
            left: 'center',
            width: 260,
            radius: ['57%', '80%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 20,
              borderColor: '#020C1D',
              borderWidth: 3,
            },
            label: {
							// alignTo: 'edge',
							formatter: '{name|{b}}\n{value|{c}}',
							minMargin: 5,
							edgeDistance: 50,
							// margin:'-50',
							lineHeight: 15,
							rich: {
								name: {
									fontSize: 16,
								},
								value: {
									fontSize: 12,
									color: '#fff',
								},
							},
						},
            labelLine: {
              normal: {
                length: 15,
                length2: 25
              }
            },
            data: this.data,
          },
        ],
      };

      // TODO ?

    },
    changeSwitch(val){
      this.isSwitchOpen=val
      this.init()
    },
    curveFun(name){
    }
	},
	watch:{
    mapLevel:{
      immediate:true,
      handler:function(){
        this.init()
      }
    },
    timeRange(){
      this.init()
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.info{
	padding: 0 12px;
}
/deep/ .el-table {
	background-color: transparent !important;
}

/deep/ .el-table,
.el-table__expanded-cell {
	background-color: transparent; //这是设置透明背景色
}
/deep/ .el-table td.el-table__cell,
/deep/ .el-table th.el-table__cell.is-leaf{
	border-bottom: 2px solid rgba(5, 41, 92, .6) !important;
}
/deep/ .el-table::before{
	background-color: transparent !important;
}

/deep/ .el-table__header-wrapper {
	tr {
		background-color: transparent;
	}
	.odd {
		font-size: 16px;
	}
	.even {
		font-size: 16px;
	}

	.el-table__cell {
		color: white;
		background-color: #153b6b;
		border-bottom-color: #081735;
	}
}

/deep/ .el-table__body-wrapper {
	.el-table__row {
		background-color: #0d2e5a !important;
	}

	.el-table__row--striped {
		.el-table__cell {
			background-color: #153b6b !important;
		}
	}
}

/deep/ .hover-row > td {
	background-color: initial !important;
}

/deep/ .first {
	div {
		color: rgba(255, 255, 255, 0.8);
	}
}

/deep/ .odd {
	color: #1fbdff;
	font-size: 20px;
}

/deep/ .even {
	color: #00f0ff;
	font-size: 20px;
}

.info {
	display: flex;
	align-items: center;

	.value {
		color: $main-text;
		font-size: 20px;
	}
}
</style>
