<template lang="">
  <el-row class="page" style="margin: 0" :gutter="16">
    <el-col :span="6">
			<LeftTop></LeftTop>
			<LeftCenter></LeftCenter>
			<LeftBottom></LeftBottom>
		</el-col>
    <el-col :span="12">
		  <CenterTop></CenterTop>
			<MapData :mapType="2" :height="663" :mapindex="0" style="margin:10px 0"></MapData>
      <div class="m_xtrend mt16">
				<div class="title">
					<div class="indicator"></div>
					<el-select v-model="lineTit" placeholder="请选择" @change="curveFun($event)">
						<el-option v-for="(v, i) in optionTit" :key="i" :label="v.label" :value="v.value">
						</el-option>
					</el-select>
				</div>
				<BaseEchart :option="option" style="width: 100%; height: 154px;"></BaseEchart>
			</div>
		</el-col>
    <el-col :span="6">
			<RightTop></RightTop>
			<RightCenter></RightCenter>
			<RightBottom></RightBottom>
		</el-col>
		<div class="content_wrap" v-if="isshowList">
			<div class="content">
				<div class="content-title">
					<div class="label">人员列表</div>
					<div class="close" @click="close">
					<img src="../../../assets/imgs/visitCloseBtn.png" alt="" />
					</div>
				</div>
				<div class="content-text">
					<div class="personInfo">
					<div class="personInfo-doctor" v-for="item in doctorList" >
						<div class="doctorName">
							<div>
								{{item.name}}（{{item.job}}）{{item.age}}岁
							</div>
							<div >
								岗位：{{item.place}} <em>|</em> 联系电话：{{item.phone}}
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
		</div>
	</el-row>
</template>
<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs'
import '@/assets/icon-font/ABDUCTION2000.css';
import Title from '../components/Title.vue'
import CenterTop from './CenterTop.vue'
import LeftTop  from "./LeftTop.vue";
import LeftCenter from './LeftCenter.vue'
import LeftBottom from './LeftBottom.vue';
import RightTop from './RightTop.vue';
import RightCenter from './RightCenter.vue';
import RightBottom from './RightBottom.vue';
import MapData from '@/views/homePage/overview/mapData.vue';
import TotalAnalysis from '../components/TotalAnalysis.vue'
import BaseEchart from '@/components/BaseEchart';
export default {
  components: { MapData, LeftCenter, LeftBottom, BaseEchart, RightTop, RightCenter, Title, TotalAnalysis, RightBottom,CenterTop,LeftTop },
  data() {
    return {
			option: undefined,
			first: [{
        name: '',
        value: [90, 100, 200, 100, 110, 280, 150, 260, 160, 90, 210, 50, 90, 100, 200, 100, 110, 280, 150, 260, 160, 90, 210, 50]
      }],
      second: [{
        name: '',
        value: [120, 280, 210, 180, 120, 280, 110, 220, 102, 56, 35, 40, 120, 280, 210, 180, 120, 280, 110, 220, 102, 56, 35, 40]
      }], 
      third: [{
        name: '',
        value: [280, 200, 200, 160, 110, 280, 150, 260, 60, 30, 200, 110, 280, 200, 200, 160, 110, 280, 150, 260, 60, 30, 200, 110]
      }],
      fourth: [{
        name : '服务建设',
        value : [180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280, 180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280]
      },{
        name : '随访',
        value : [210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100, 210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100]
      }],
      fifth: [{
        name : '高血压',
        value : [0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40, 0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40]
      },{
        name : '糖尿病',
        value : [60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0, 60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0]
      }],
      sixth: [{
        name: '',
        value: [80, 280, 210, 180, 120, 280, 110, 200, 102, 56, 35, 40, 80, 280, 210, 180, 120, 280, 110, 200, 102, 56, 35, 40]
      }], 
      lineArr : [],
			isTit: false,
      lineTit : '基础数据',
			optionTit: [{
				value: '基础数据',
				label: '建设基础数据增长趋势'
			}, {
				value: '慢性病管理',
				label: '建设慢性病管理增长趋势'
			}, {
				value: '教育宣传',
				label: '建设教育宣传增长趋势'
			}, {
				value: '服务体系',
				label: '建设服务体系增长趋势'
			}, {
				value: '工作指标',
				label: '建设工作指标增长趋势'
			}, {
				value: '业务培训',
				label: '建设业务培训增长趋势'
			}],
			color : [
				{up: '#0EB7F4', down: 'rgba(14,183,244,.1)'},
				{up: '#FF7B00', down: 'rgba(255,123,0,.1)'},
				{up: '#58E41B', down: 'rgba(88,228,27,.1)'},
				{up: '#F4AB0E', down: 'rgba(244,171,14,.1)'},
			],
			isshowList:false,
			doctorList: [
				{
					name: '张某某',
					age: '33',
					job: '副主任医师',
					place: '内科医生',
					phone:'18340910447'
				},
				{
					name: '赵某某',
					age: '30',
					job: '副主任药师',
					phone:'18340910447',
					place: '内科医生'
				},
				{
					name: '刘某某',
					age: '25',
					job: '主任药师',
					place: '内科医生',
					phone:'18340910447'

				},
				{
					name: '刘某某',
					age: '25',
					job: '副主任医师',
					place: '外科医生',
					phone:'18340910447'

				},
				{
					name: '刘某某',
					age: '25',
					job: '副主任医师',
					place: '外科医生',
					phone:'18340910447'

				},
				{
					name: '刘某某',
					age: '25',
					job: '副主任医师',
					place: '外科医生',
					phone:'18340910447'

				},
				{
					name: '刘某某',
					age: '25',
					job: '副主任医师',
					place: '外科医生',
					phone:'18340910447'

				},
				{
					name: '刘某某',
					age: '25',
					job: '副主任医师',
					place: '外科医生',
					phone:'18340910447'

				},
			],
    }
  },
	computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr', 'heads']),
    heads() {
      return this.$store.state.homePage.heads
    },
    timeRange() {
			return this.$store.state.homePage.timeRange
		},
		timeRangeAndMapLevel() {
			return this.timeRange + this.mapLevel
		}
  },
  beforeCreate() {
    this.$store.state.homePage.heads = {name: '', index: 1};
  },
  watch: {
    mapLevel: {
      immediate: true,
      handler: function(){
        if(this.lineArr.length == 0) this.lineArr = this.first
				let as = this.color
				this.lineArr.map((v, i) => { v.color = as[i] })
        this.wireFun()
      }
    },
    timeRangeAndMapLevel: {
			handler(val) {
				let as = this.color
				this.lineArr.map((v, i) => { v.color = as[i] })
				this.wireFun()
			},
			immediate: true
		},
    heads: {
      immediate: true,
      handler: function(val){
        if(val.name != ''){
          this.curveFun(val)
        }
      }
    }
  },
  mounted() {
	this.bus.$on('show_doctor', (res) => {
			if(res==2){
				this.isshowList = true
			}
		})
  },
  methods: {
		deal(arr) {
			return arr.map(d => {
				return Math.round(d / (this.mapLevel + 1))
			})
		},
    wireFun() {
			let data = []
			let start = this.timeRange[0]
			let end = this.timeRange[1]
			let months = []
			while(start <= end){
				months.push(start.getMonth() + 1)
				start = dayjs(start).add(1, 'month').toDate()
			}
			let us = {}
			this.lineArr.map(v => {
				us = {
					name: v.name,
					data: this.deal(v.value),
					symbol: 'circle',
					type: 'line',
					smooth: true,
					symbol: "none",
					lineStyle: {
						color: v.color.up
					},
					itemStyle: {
						color: v.color.up
					},
					areaStyle: {
						color: {
							type: 'linear',
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [{
								offset: 0, color: v.color.up // 0% 处的颜色
							}, {
								offset: 1, color: v.color.down // 100% 处的颜色
							}],
						}
					}
				}
				data.push(us)
			})
			this.option = ({
				axisLabel: {
					color: '#fff',
					fontSize: 12
				},
				legend: {
					show: true,
					icon: 'stack',
					top: 6,
					itemWidth: 10,
					itemHeight: 5,
					itemColor: '#fff',
					textStyle: {
						fontSize: 16,
						color: '#fff', // 设置字体颜色
						padding: [0, 5] // 设置文字与图例的距离
					},
					itemGap: 20,// 设置图例项之间的间距
					itemStyle: {
						borderWidth: 0,
					}
				},
				grid: {
					left: 40,
					right: 40,
					top: 48,
					bottom: 32
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					splitLine: {
						show: true,
						lineStyle: {
							opacity: 0.2,
							type: [5, 5],
							dashOffset: 0,
							shadowBlur: 0
						},
					},
					data: months.map(m => m + '月')
				},
				yAxis: {
					type: 'value',
					splitNumber: 4,
					min: 0,
					// max: 280,
					splitLine: {
						show: true,
						lineStyle: {
							opacity: 0.2,
							type: [5, 5],
							dashOffset: 0,
							shadowBlur: 0
						},
					},
				},
				series: data
			})
			this.$forceUpdate();
		},
		curveFun(val){
			let name = val
			if(typeof val == 'object'){
				name = val.name
			}
      this.lineTit = name 
      this.isTit = false
      if(val.index > 1) this.isTit = true
      let arr = []
      if(name == '基础数据'){
        arr = this.first
      }else if(name == '慢性病管理'){
        arr = this.second
      }else if(name == '教育宣传'){
        arr = this.third
      }else if(name == '服务体系'){
        arr = this.fourth
      }else if(name == '工作指标'){
        arr = this.fifth
      }else if(name == '业务培训'){
        arr = this.sixth
      }
			let as = this.color
			arr.map((v, i) => { v.color = as[i] })
      this.lineArr = arr
      this.wireFun()
		},
		close() {
			this.isshowList = false
		},
		openVideoCall() {

},
  },
  destroyed(){
    this.$store.state.homePage.heads = {name: '', index: 1};
  }
}
</script>
<style lang="scss" scoped>
.m_xtrend{
	background: rgba(5, 41, 92, .3);
	position: relative;
  border: 2px solid #1D3468;
	.title {
		position: absolute;
		left: 0;
		top:0;
		height: 32px;
		padding-left: 16px;
		font-size: 16px;
		display: flex;
		align-items: center;
		z-index: 10;
		.indicator {
			height: 16px;
			width: 4px;
			margin-right: 8px;
			background-image: linear-gradient(#308FCC, #224ABE);
		}
	}
	/deep/ .el-input__inner{
		background: none;
		font-size: 18px;
		color:#fff;
		font-weight: bold;
		padding: 0;
		border: none;
	}
	/deep/ .el-input__icon{
		line-height: 32px;
		color:#1C87FF;
	}
}
.content_wrap {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	margin: 0;
	background-color: rgba(145, 142, 142, 0.233);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 222;

	.content {
		width: 400px;
		height: 550px;
		background: url(../../../assets/imgs/visitGroupBg.png) no-repeat;
		background-size: 500px 550px;
		&-title {
			height: 52px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;
			background: url('../../../assets/imgs/dialogTitleBg.png') no-repeat center;
			background-size: 100% 100%;
			color: #fff;
		}

		.label {
			margin-top: 6px;
			font-size: 24px;
			line-height: 22px;
			height: 25px;
			background: url('../../../assets/imgs/线.png') no-repeat bottom;
			background-size: 100% 5px;
		}

		.close {
			display: flex;
			align-items: center;
			margin-top: 6px;

			img {
				width: 28px;
				height: 28px;
				cursor: pointer;
			}
		}

		&-text {
			max-height: 490px;
			overflow-y: auto;

			.personInfo {
				padding: 0 18px;
				padding-top: 16px;

				&-doctor {
					background: rgba(12, 49, 94, 0.65);
					border-radius: 6px;
					display: flex;
					position: relative;
					margin-top: 5px;


					.doctorName {
						padding: 15px 0;
						color: #fff;
						height: 70px;
						display: flex;
						flex-direction: column;
						text-indent: 2em;
						justify-content: space-around;
						div{
							width: 100%;
						}
						div:nth-child(2){
							font-size: 14px;
							font-weight: 300;
							em{
								font-style: normal;
								margin: 5px;
							}
						}
					}
				}
			}
		}
	}
}
</style>